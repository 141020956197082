.page {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 52px 1fr 52px;
  grid-template-rows: 52px 1fr;
  grid-template-areas:
      "topnav topnav topnav"
      "sidenav-left content sidenav-right";
}

.page * ul {
  padding: 0;
  margin: 0;
}

.page * li {
  list-style: none;
}

.topnav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-area: topnav;
  background: var(--amazon-squid-ink);
  color: white;
}

.topnav-1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 52px;
  width: 200px;
  margin: 0 0 0 2rem;
  color: var(--grey-400);
  font-size: 1.6rem;
}

.topnav-1 img {
  height: 27px;
  width: auto;
}

.topnav-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 200px;
  color: var(--grey-400);
  font-size: 1.6rem;
}

.topnav-2 img {
  height: 20px;
  width: auto;
}

.topnav-3 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 200px;
  max-width: 500px;
  color: var(--grey-400);
  font-size: 1.4rem;
}

.topnav-3 ul > li {
  display: inline-block;
  margin-right: 10px;
}

.topnav-3 .profile {
  height: 30px;
  width: 30px;
}

.account {
  height: 28px;
  width: 28px;
  //background: var(--grey-550);
  border-radius: 4px;
}

.account img.profile {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.sidenav-left {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: var(--white);
  border-right: 1px solid var(--grey-200);
}

.sidenav-left * img {
  height: 18px;
  width: auto;
  margin: 2rem 0 1.2rem 0;
  color: var(--orange-500);
  cursor: pointer;
}

.content {
  grid-area: content;
  overflow-y: auto;
  padding: 1.8rem 2rem 2rem 2rem;
  margin: 2rem 0 0 0;
}

.big-spinner * {
  font-size: large !important;
}

.custom-flashbar {
  width: 70%;
  position: fixed;
  left: 0;
  right: 0;
  margin: 5rem 0 0 10rem;
  z-index: 9999;

  div[class*='awsui_flash_'] {
    border-radius: 5px !important;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25) !important;
  }
}