:root {
    --amazon-orange: #FF9900;
    --amazon-squid-ink: #232F3E;
    --grey-900: #16191f;
    --grey-600: #545B64;
    --grey-550: #687078;
    --grey-500: #879596;
    --grey-400: #AAB7B8;
    --grey-300: #D5DBDB;
    --grey-200: #EAEDED;
    --grey-150: #F2F3F3;
    --grey-100: #FAFAFA;
    --white: #FFFFFF;
    --orange-700: #DD6B10;
    --orange-500: #EB5F07;
    --orange-600: #EC7211;
    --blue-600: #0073BB;
    --blue-500: #00A1C9;
    --blue-300: #99CBE4;
    --blue-100: #F1FAFF;
    --red-600: #D13212;
    --red-100: #FDF3F1;
    --green-600: #1D8102;
    --green-200: #E5F2E1;
    --green-100: #F2F8F0;
}

html {
    font-size: 62.5%;
}

body {
    margin: 0;
    background: var(--grey-100);
    font-size: 62.5%;
}

div.section {
    &:first-of-type {
        margin-top: 30px;
    }
    margin-bottom: 30px;
    opacity: 1;

    &.hidden {
        opacity: 0;
    }

    &:not(.hidden) {
        animation-name: fadeIn;
        animation-duration: 1s;
    }
}

.foo { flex: 1; }

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.float-right {
    float: right;
}

.text-right {
    text-align: right;
}

.middle-align {
    vertical-align: middle;
}